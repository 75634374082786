.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*
 ##Device = Most of the Smartphones Mobiles (Portrait)
 ##Screen = B/w 320px to 479px
*/

@media (max-width: 600px) {

    #side-bar div.MuiDrawer-paperAnchorLeft {
        background-color: rgb(92, 68, 119)
    }

}

#side-bar {
    height: unset;
}

#AppBar > div:first-child {
    min-height: 70px
}

.scale-11 {
    transform: scale(1.05);
}

.more-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.logout > div {
    color: rgba(0, 0, 0, 0.544)
}

/* @media (min-width: 30em) {
    .filepond--item {
        width: calc(50% - .5em);
    }
}

@media (min-width: 50em) {
    .filepond--item {
        width: calc(33.33% - .5em);
    }
} */

@media (min-width: 1024px) {
    .list-page .filter-field:first-child {
        width: 450px
    }

    .list-page .filter-field:first-child > div {
        min-width: 100% !important;
    }
}

/*th:not(:first-child) {
    padding: 0 12px !important;
}*/

/* Hide loading indicator */
/*header span + button {
    visibility: hidden;
}*/

.slick-slide {
    text-align: center;
}

.slick-slide, .slick-slide * {
    outline: none !important;
}

.__react_component_tooltip.type-dark.place-right:after {
    border-right-color: '#464648a1'
}

*:focus {
    outline: none;
}

.list-page tr th:first-child {
    /*padding-left: 12px*/
}

.list-page tr th:first-child > .select-all {
    /*margin-left: -12px*/
}

/*styling for my account list*/
.myaccount-list {
    padding: 0 15px;
}

.myaccount-list th {
    border-bottom: none;
    background-color: #f3eff8;
    color: #6b6279;
}

.myaccount-list td {
    border-bottom: none;
}

.signin-break-wrapper {
    background-color: #cbcbcb;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    width: 100%;
}

#root {
    height: 100vh;
}

#root > div {
    height: 100%;
}

.tag-remove:before {
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
}

div[class^="RaLayout-appFrame-"] {
    margin-top: 60px;
}

.list-page > .MuiToolbar-root {
    padding: 0 15px;
}

.MuiTableCell-head {
    padding: unset !important;
}

.MuiTableCell-head {
    padding: 6px 12px 6px 16px !important;
}

.list-page, .edit-page > div > button {
    margin: 20px 30px;
}

button[title="Refresh"] {
    display: none;
}

button[title="Refresh"] > span {
    display: none;
}

button[title="Refresh"] > span > svg {
    color: transparent;
    display: none;
}

div[role="progressbar"] {
    display: none;
}

/*#left-menu > a:hover{*/
/*    background-color: rgba(255, 255, 255, 1);*/
/*    color:  rgba(125, 47, 171, 1)*/
/*}*/
#left-menu > .MuiListItem-button:hover {
    background-color: rgba(125, 47, 171, 0.5);
    /*font-weight: bold;*/
}

#left-menu > .MuiListItemText-root > p:hover,
.MuiListItem-button > .MuiListItemText-multiline:hover {
    /*background-color:rgba(125, 47, 171, 0.5);*/
    /*font-weight: bold;*/
}

a.MuiListItem-button:hover {
    background-color: rgba(125, 47, 171, 0.5);
    /*font-weight: bold;*/
}

a.MuiListItem-button[aria-current="page"] {
    background-color: rgb(117, 53, 154);
    font-weight: bold;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #000 !important;
    /*-webkit-box-shadow: 0 0 0 1000px white inset !important*/

}

#cardDraw {
    position: relative;
    /*padding: 20px 50px;*/
    outline: none;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-image: linear-gradient(#ad21f5, #ad21f5),
    linear-gradient(#ad21f5, #ad21f5),
    linear-gradient(#ad21f5, #ad21f5),
    linear-gradient(#ad21f5, #ad21f5);

    background-color: #f6f6f6;
    background-repeat: no-repeat;
    transition: 0.2s linear;
    background-position: 0 100%, 0 0, 0 100%, 100% 0;
    background-size: 1px 0%, 100% 1px, 0% 1px,1px 0%;
    animation: cardAnimate 1.4s linear infinite 0.2s;
}
#cardDraw:hover {
    background-position: 0 100%, 0 0, 0 100%, 100% 0;
    background-size: 1px 0%, 100% 1px, 0% 1px,1px 0%;
    animation: cardAnimate 1.4s linear infinite 0.2s;
}

#cardDraw:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border:1px solid #60daaa;
}

#btnDraw {

    position: relative;
    /*padding: 20px 50px;*/
    outline: none;
    border: none;
    box-shadow: none;
    background-image: linear-gradient(#56067f, #56067f),
    linear-gradient(#56067f, #56067f),
    linear-gradient(#56067f, #56067f),
    linear-gradient(#56067f, #56067f);

    /*background-position: 0 0, 0 0, 0 100%, 0 100%;*/
    /*background-size: 3px 0%, 0% 3px, 0% 3px, 3px 0%;*/
    background-color:transparent;
    background-repeat:no-repeat;
    transition: 0.2s linear;
    background-position: 0 100%, 0 0, 0 100%, 100% 0;
    background-size: 3px 0%, 100% 3px, 0% 3px,3px 0%;
    animation: animate 1.4s linear infinite 0.5s;
}
/*#btnDraw:before {*/
/*    content:"";*/
/*    position:absolute;*/
/*    z-index:-1;*/
/*    top:0;*/
/*    right:0;*/
/*    left:0;*/
/*    bottom:0;*/
/*    !*border:3px solid #60daaa;*!*/
/*}*/

/*#btnDraw:hover {*/
/*    background-position: 0 100%, 0 0, 0 100%, 100% 0;*/
/*    background-size: 3px 0%, 100% 3px, 0% 3px,3px 0%;*/
/*    animation: animate 1.4s linear infinite 0.2s;*/
/*}*/

@keyframes animate {
    0% {
        background-position: 0 100%, 0 0, 0 100%, 100% 0;
        background-size: 3px 0%, 100% 3px, 0% 3px, 3px 0%;
    }
    40% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 0;
        background-size: 3px 0%, 100% 3px, 0% 3px, 3px 100%;
    }
    60% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 100%;
        background-size: 3px 0%, 0% 3px, 100% 3px, 3px 100%
    }
    70% {
        background-position: 0 100%, 100% 0, 0% 100%, 100% 100%;
        background-size: 3px 100%, 0% 3px, 100% 3px, 3px 0%;
    }
    80% {
        background-position: 0% 0%, 0% 0, 0% 100%, 100% 100%;
        background-size: 3px 100%, 0% 3px, 0% 3px, 3px 0%;
    }
    100% {
        background-position: 0% 0%, 0 0, 0 100%, 100% 100%;
        background-size: 3px 0%, 100% 3px, 0% 3px, 3px 0%
    }
}
@keyframes cardAnimate {
    0% {
        background-position: 0 100%, 0 0, 0 100%, 100% 0;
        background-size: 1px 0%, 100% 1px, 0% 1px, 1px 0%;
    }
    40% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 0;
        background-size: 1px 0%, 100% 1px, 0% 1px, 1px 100%;
    }
    60% {
        background-position: 0 100%, 100% 0, 100% 100%, 100% 100%;
        background-size: 1px 0%, 0% 1px, 100% 1px, 1px 100%
    }
    70% {
        background-position: 0 100%, 100% 0, 0% 100%, 100% 100%;
        background-size: 1px 100%, 0% 1px, 100% 1px, 1px 0%;
    }
    80% {
        background-position: 0% 0%, 0% 0, 0% 100%, 100% 100%;
        background-size: 1px 100%, 0% 1px, 0% 1px, 1px 0%;
    }
    100% {
        background-position: 0% 0%, 0 0, 0 100%, 100% 100%;
        background-size: 1px 0%, 100% 1px, 0% 1px, 1px 0%
    }
}

