.login-form {
    padding: 2em 3em;
    position: relative;
    background: #2f1f3b80;
    color: white;
    width: auto;
}

.login-form input {
    /*color: white;*/
}

.login-form:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    height: 2px;
    width: 100%;
    background: rgb(180, 152, 219);
}

.vkey.flex-row {
    display: flex;
    /*margin-bottom: 1em;*/
    background-color: #70707073;
    margin-bottom: 0px;
    border-top: 1px solid #b9b0c3;
    height: 65px;
}

.vkey.flex-row:first-child {
    margin-bottom: 0px;
    border-top: none;
}

::placeholder {
    color: rgba(255, 255, 255, .5);
}

::-webkit-input-placeholder { /* Edge */
    color: rgba(255, 255, 255, .5);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, .5);
}

.lf--label {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: #f5f6f8;*/
    cursor: pointer;
    color: white;
    padding-left: 12px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .vkey .flex-row {
        display: flex;
        /*margin-bottom: 1em;*/
        background-color: #70707073;
        margin-bottom: 0px;
        border-top: 1px solid #b9b0c3;
        height: 45px;
    }

    .lf--label {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        /*background: #f5f6f8;*/
        cursor: pointer;
        color: white;
        padding-left: 12px;
    }

    .lf--input {
        flex: 1;
        padding: 1em;
        border: 0;
        color: #ffffff;
        font-size: 1rem;
        background: none;
    }
}

.lf--input {
    flex: 1;
    padding: 1em;
    border: 0;
    color: #ffffff;
    font-size: 16px;
    background: none;
}

.lf--input:focus {
    /*outline: none;*/
    /*transition: transform 0.15s ease;*/
    /*transform: scale(1.1);*/
}

.lf--submit {
    display: block;
    padding: 1em;
    width: 100%;
    background: none;
    border: 0;
    color: #ffb73e;
    cursor: pointer;
    font-size: 0.75em;
    font-family: sans-serif;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .2);
}

.lf--submit:focus {
    outline: none;
    transition: transform 0.15s ease;
    transform: scale(1.1);
}

.lf--forgot {
    margin-top: 1em;
    color: #ffe50a;
    font-size: 0.65em;
    text-align: center;
    position: relative;
}

.lf--message {
    font-size: 16px;
    text-align: center;
    width: 70%;
    margin-top: 1.5em;
    margin-left: auto;
    margin-right: auto;
}

.lf-phone--input {
    height: 100% !important;
    border-radius: unset !important;
    border: none !important;
    font-size: 16px !important;
    background: none !important;
    padding-left: 15px !important;
    width: 100% !important;
    margin-left: 36px !important;
}

.phone-icon-container {
    width: 72px;
}

.react-tel-input .flag-dropdown {
    background-color: unset !important;
    padding-left: 10px !important;
    border-radius: unset !important;
    border: none !important;
}

.react-tel-input .phone-dropdown {
    margin-top: 0px !important;
}

.react-tel-input .flag-dropdown .selected-flag {
    border-radius: unset !important;
} 

.react-tel-input .country-list {
    text-align: left !important;
}

.myaccountform .lf-phone--input {
    height: 100% !important;
    border-radius: unset !important;
    border: none !important;
    font-size: 16px !important;
    background: none !important;
    padding-left: 15px !important;
    width: 100% !important;
    margin-left: 36px !important;
    color: #33263b;
}

.myaccountform.phone-container {
    border: solid 0.5px #5f2685;
    border-radius: 7px;
    height: 40px;
}

.myaccountform.phone-container .lf-phone--input {
    font-size: 11px !important;
}

/*background-color: #2f1f3b80 !important;*/

/* .react-tel-input .flag-dropdown {
    
    background-color: transparent !important;
}

.react-tel-input .flag-dropdown:hover {
    background-color: transparent !important;
}

.react-tel-input .flag-dropdown .selected-flag:hover {
    background-color: transparent !important;
} */

#error-message {
    color: #ff4e00;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

/*Change text in autofill textbox*/
.lf--input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
}
